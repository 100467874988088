import React from "react";
import { Route, Navigate } from "react-router-dom";
import Dashboard from "../feature-module/dashboard/Dashboard";

const routes = all_routes;

import SigninTwo from "../feature-module/pages/login/signinTwo";
import { all_routes } from "./all_routes";
import UomList from "../pages/products/uom/uom-list";
import UomForm from "../pages/products/uom/uom-form";
import AttributeList from "../pages/products/attributes/attribute-list";
import AttributeForm from "../pages/products/attributes/attribute-form";
import CategoryList from "../pages/products/categories/category-list";
import CategoryForm from "../pages/products/categories/category-form";
import BrandList from "../pages/products/brands/brand-list";
import BrandForm from "../pages/products/brands/brand-form";
import DesignationList from "../pages/people/designations/designation-list";
import SupplierList from "../pages/people/supplier/supplier-list";
import VendorList from "../pages/people/vendors/vendor-list";
import VendorForm from "../pages/people/vendors/vendor-form";
import VendorDetail from "../pages/people/vendors/vendor-detail";
import SupplierForm from "../pages/people/supplier/supplier-form";
import SupplierDetails from "../pages/people/supplier/supplier-details";
import EmployeesDetails from "../pages/people/employees/employees-details";
import EmployeesForm from "../pages/people/employees/employees-form";
import EmployeesList from "../pages/people/employees/employees-list";
import ContactList from "../pages/people/contact/contact-list";
import ContactForm from "../pages/people/contact/contact-form";
// import VatList from "../pages/settings/vat/vat-list";
// import TimezoneList from "../pages/settings/timezone/timezone-list";
import SystemLanguageList from "../pages/settings/system-language/system-language-list";
// import CurrencyList from "../pages/settings/currency/currency-list";
import OutletsDetails from "../pages/stores/outlets/outlets-details";
import OutletsForm from "../pages/stores/outlets/outlets-form";
import OutletsList from "../pages/stores/outlets/outlets-list";
import VarientDetails from "../pages/products/variant/variant-details";
import VarientForm from "../pages/products/variant/variant-form";
import VarientList from "../pages/products/variant/variant-list";
import CatalogDetails from "../pages/products/catalog/catalog-details";
import CatalogForm from "../pages/products/catalog/catalog-form";
import CatalogList from "../pages/products/catalog/catalog-list";
import PriceLineList from "../pages/products/price-line/price-line-list";
import MyProfile from "../pages/profile/my-profile/profile";

import MainStockList from "../pages/inventory/mainstock/main-stock-list";
import MainStockForm from "../pages/inventory/mainstock/main-stock-form";
import DistributionList from "../pages/inventory/distribution/distribution-list";
import OutletStockList from "../pages/inventory/outletstock/outlet-stock-list";
import ProductTypeList from "../pages/products/product-type/product-type-list";
import PledgePriceList from "../pages/products/pledge-price/pledge-price-list";
import PledgePriceForm from "../pages/products/pledge-price/pledge-price-form";
import RoleList from "../pages/user-managment/role/role-list";
import RoleForm from "../pages/user-managment/role/role-form";
import PurchaseList from "../pages/Purchases/purchases/purchase-list";
import PurchaseDetails from "../pages/Purchases/purchases/purchase-details";
import ProductDetails from "../pages/products/product/product-details";
import ProductForm from "../pages/products/product/product-form";
import ProductList from "../pages/products/product/product-list";
import ProductGroupList from "../pages/products/product-group/product-group-list";
import ProductGroupForm from "../pages/products/product-group/product-group-form";
import ProductGroupListIndex from "../pages/products/product-group-list/product-group-list-index";
import ProductFromCatalog from "../pages/products/product/product-from-catalog";
import AddProductGroupList from "../pages/products/product-group-list/add-product-group-list";
import CampaignList from "../pages/products/campaign/campaign-list";
import CampaignForm from "../pages/products/campaign/campaign-form";
import CampaignDetails from "../pages/products/campaign/campaign-details";
import UserList from "../pages/user-managment/user/user-list";
import UserForm from "../pages/user-managment/user/user-form";
import CustomersList from "../pages/people/customers/customers-list";
import CustomersDetails from "../pages/people/customers/customers-details";
import TagTypeList from "../pages/products/tagType/tag-type-list";
import VerifyOtp from "../feature-module/pages/verifyotp/verifyotp";
import GS1List from "../pages/products/GS1/gs1-list";
import LiveStream from "../pages/products/LiveStream";
import ThreedFilePage from "../pages/sample/3d-file";
import CountryList from "../pages/settings/country/country-list";
import CountryForm from "../pages/settings/country/country-form";
import CatalogImageDetails from "../pages/products/catalog/catalog-images-detail";
import ThreedFileWindowIphoneExpanded from "../pages/sample/sample-iphone/3d-window-expanded";
import TicketDetails from "../pages/support/contact/ticket-details";
import TicketList from "../pages/support/contact/ticket-list";
import CountryDetails from "../pages/settings/country/country-details";
import PurchaseUpload from "../pages/Purchases/purchases/purchase-upload";
import LogsList from "../pages/settings/backend-logs/logs-list";
import UpdateLanguageJson from "../pages/settings/system-language/language-json-upload";
import ForgotpasswordTwo from "../feature-module/pages/forgotpassword/forgotpasswordTwo";
import PriceLineFromPurchase from "../pages/products/price-line/price-line-from-purchase";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import MainStockFromPurchase from "../pages/inventory/mainstock/main-stock-from-purchase";
import ProductImageDetails from "../pages/products/product/product-Image-detail";
import DistributionFromMainStock from "../pages/inventory/distribution/distribution-from-main-stock";
import ThirdPartyList from "../pages/thirdParty/thirdParty/thirdParty-list";
import SalesList from "../pages/Sales/Sales/sales-list";
import SalesDetails from "../pages/Sales/Sales/sales-details";
import ProductGroupDetails from "../pages/products/product-group/product-group-details";
import LoyaltySettingsList from "../pages/loyalty/loyalty/loyalty-settings-list";
import PriceLineDetails from "../pages/products/price-line/price-line-details";
import NotificationList from "../pages/settings/notification/notification-list";
import NotificationForm from "../pages/settings/notification/notification-form";
import ObjectUpload from "../pages/products/catalog/object-upload";
import { OutletLayout } from "../pages/stores/outlets/outlet-layout";
import OutletLogsList from "../pages/stores/outlet-logs/outlet-logs-list";
import CCTVList from "../pages/stores/cctv/cctv-list";
import FaqList from "../pages/settings/faq/faq-list";
import FaqTypeList from "../pages/settings/faq-type/faq-type-list";

export const privateRoutes = [
  {
    id: 1,
    path: routes.dashboard,
    name: "home",
    element: <Dashboard />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 5,
    path: routes.brandlist,
    name: "brand",
    element: <BrandList />,
    perm: "brand-view",
    route: Route,
  },
  {
    id: 5,
    path: routes.brandcreate,
    name: "brand-create",
    element: <BrandForm />,
    perm: "brand-add",
    route: Route,
  },
  {
    id: 5,
    path: routes.brandedit,
    name: "brand-edit",
    element: <BrandForm />,
    perm: "brand-edit",
    route: Route,
  },
  {
    id: 60,
    path: routes.categorylist,
    name: "category-list",
    element: <CategoryList />,
    perm: "category-view",
    route: Route,
  },
  {
    id: 60,
    path: routes.categoryCreate,
    name: "category-create",
    element: <CategoryForm />,
    perm: "category-add",
    route: Route,
  },
  {
    id: 60,
    path: routes.categoryEdit,
    name: "category-edit",
    element: <CategoryForm />,
    perm: "category-edit",
    route: Route,
  },
  {
    id: 6,
    path: routes.uomList,
    name: "uom-list",
    element: <UomList />,
    perm: "uom-view",
    route: Route,
  },
  {
    id: 6,
    path: routes.uomCreate,
    name: "uom-create",
    element: <UomForm />,
    perm: "uom-add",
    route: Route,
  },
  {
    id: 6,
    path: routes.uomEdit,
    name: "uom-edit",
    element: <UomForm />,
    perm: "uom-edit",
    route: Route,
  },
  {
    id: 6,
    path: routes.notificationList,
    name: "notification-list",
    element: <NotificationList />,
    perm: "notification-view",
    route: Route,
  },
  {
    id: 6,
    path: routes.notificationCreate,
    name: "notification-create",
    element: <NotificationForm />,
    perm: "notification-add",
    route: Route,
  },
  {
    id: 6,
    path: routes.notificationEdit,
    name: "notification-edit",
    element: <NotificationForm />,
    perm: "notification-edit",
    route: Route,
  },
  {
    id: 6,
    path: routes.attributeList,
    name: "attribute-list",
    element: <AttributeList />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 6,
    path: routes.attributeCreate,
    name: "attribute-create",
    element: <AttributeForm />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 6,
    path: routes.attributeEdit,
    name: "attribute-edit",
    element: <AttributeForm />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 40,
    path: routes.designationList,
    name: "designation-list",
    element: <DesignationList />,
    perm: "designation-view",
    route: Route,
  },
  {
    id: 40,
    path: routes.tagTypeList,
    name: "tag-type-list",
    element: <TagTypeList />,
    perm: "tag-type-view",
    route: Route,
  },
  {
    id: 46,
    path: routes.supplierList,
    name: "supplier-list",
    element: <SupplierList />,
    perm: "supplier-view",
    route: Route,
  },
  {
    id: 47,
    path: routes.supplierCreate,
    name: "supplier-create",
    element: <SupplierForm />,
    perm: "supplier-add",
    route: Route,
  },
  {
    id: 48,
    path: routes.supplierEdit,
    name: "supplier-edit",
    element: <SupplierForm />,
    perm: "supplier-edit",
    route: Route,
  },
  {
    id: 48,
    path: routes.supplierDetails,
    name: "supplier-details",
    element: <SupplierDetails />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 41,
    path: routes.vendorList,
    name: "vendor-list",
    element: <VendorList />,
    perm: "vendor-view",
    route: Route,
  },
  {
    id: 41,
    path: routes.vendorCreate,
    name: "vendor-create",
    element: <VendorForm />,
    perm: "vendor-add",
    route: Route,
  },
  {
    id: 41,
    path: routes.vendorEdit,
    name: "vendor-edit",
    element: <VendorForm />,
    perm: "vendor-edit",
    route: Route,
  },
  {
    id: 41,
    path: routes.vendorDetail,
    name: "vendor-detail",
    element: <VendorDetail />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 115,
    path: "/",
    name: "Root",
    element: <Navigate to="/" />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 117,
    path: routes.employeesList,
    name: "employees-list",
    element: <EmployeesList />,
    perm: "employee-view",
    route: Route,
  },
  {
    id: 118,
    path: routes.employeesCreate,
    name: "employees-create",
    element: <EmployeesForm />,
    perm: "employee-add",
    route: Route,
  },
  {
    id: 119,
    path: routes.employeesEdit,
    name: "employees-edit",
    element: <EmployeesForm />,
    perm: "employee-edit",
    route: Route,
  },
  {
    id: 120,
    path: routes.employeesDetails,
    name: "employees-details",
    element: <EmployeesDetails />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 120,
    path: routes.CustomersDetails,
    name: "customers-details",
    element: <CustomersDetails />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 117,
    path: routes.campaignList,
    name: "campaign-list",
    element: <CampaignList />,
    perm: "campaign-view",
    route: Route,
  },
  {
    id: 118,
    path: routes.campaignCreate,
    name: "campaign-create",
    element: <CampaignForm />,
    perm: "campaign-add",
    route: Route,
  },
  {
    id: 119,
    path: routes.campaignEdit,
    name: "campaign-edit",
    element: <CampaignForm />,
    perm: "campaign-edit",
    route: Route,
  },
  {
    id: 120,
    path: routes.campaignDetails,
    name: "campaign-details",
    element: <CampaignDetails />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 121,
    path: routes.contactList,
    name: "contact-list",
    element: <ContactList />,
    perm: "contact-view",
    route: Route,
  },
  {
    id: 120,
    path: routes.ticketDetails,
    name: "ticket-details",
    element: <TicketDetails />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 121,
    path: routes.ticketList,
    name: "ticket-list",
    element: <TicketList />,
    perm: "ticket-view",
    route: Route,
  },
  {
    id: 121,
    path: routes.thirdPartyList,
    name: "third-party-list",
    element: <ThirdPartyList />,
    perm: "third-party-view",
    route: Route,
  },
  {
    id: 181,
    path: routes.loyaltySettingsList,
    name: "loyalty-settings-list",
    element: <LoyaltySettingsList/>,
    perm: "loyalty-point-setting-view",
    route: Route,
  },
  {
    id: 121,
    path: routes.customersList,
    name: "customers-list",
    element: <CustomersList />,
    perm: "customer-view",
    route: Route,
  },
  {
    id: 122,
    path: routes.contactCreate,
    name: "contact-create",
    element: <ContactForm />,
    perm: "contact-add",
    route: Route,
  },
  {
    id: 123,
    path: routes.contactEdit,
    name: "contact-edit",
    element: <ContactForm />,
    perm: "contact-edit",
    route: Route,
  },
  {
    id: 121,
    path: routes.userList,
    name: "user-list",
    element: <UserList />,
    perm: "user-view",
    route: Route,
  },
  {
    id: 122,
    path: routes.userCreate,
    name: "user-create",
    element: <UserForm />,
    perm: "user-add",
    route: Route,
  },
  {
    id: 123,
    path: routes.userEdit,
    name: "user-edit",
    element: <UserForm />,
    perm: "user-edit",
    route: Route,
  },
  {
    id: 126,
    path: routes.systemLanguageList,
    name: "system-language-list",
    element: <SystemLanguageList />,
    perm: "language-view",
    route: Route,
  },
  {
    id: 126,
    path: routes.FaqList,
    name: "faq-list",
    element: <FaqList />,
    perm: "language-view",
    route: Route,
  },
  {
    id: 126,
    path: routes.FaqTypeList,
    name: "faq-type-list",
    element: <FaqTypeList />,
    perm: "language-view",
    route: Route,
  },
  {
    id: 126,
    path: routes.systemLanguageJson,
    name: "system-language-Json",
    element: <UpdateLanguageJson />,
    perm: "json-translation-create",
    route: Route,
  },
  {
    id: 127,
    path: routes.outletLogsList,
    name: "outlets-list",
    element: <OutletLogsList />,
    perm: "outlet-view",
    route: Route,
  },
  {
    id: 127,
    path: routes.cctvList,
    name: "cctv-list",
    element: <CCTVList />,
    perm: "outlet-view",
    route: Route,
  },
  {
    id: 127,
    path: routes.outletsList,
    name: "outlets-list",
    element: <OutletsList />,
    perm: "outlet-view",
    route: Route,
  },
  {
    id: 128,
    path: routes.outletsCreate,
    name: "outlets-create",
    element: <OutletsForm />,
    perm: "outlet-add",
    route: Route,
  },
  {
    id: 129,
    path: routes.outletsEdit,
    name: "outlets-edit",
    element: <OutletsForm />,
    perm: "outlet-edit",
    route: Route,
  },
  {
    id: 130,
    path: routes.outletsDetails,
    name: "outlets-details",
    element: <OutletsDetails />,
    perm: "outlet-view",
    route: Route,
  },
  {
    id: 130,
    path: routes.outletsLayout,
    name: "outlets-layout",
    element: <OutletLayout />,
    perm: "outlet-view",
    route: Route,
  },
  {
    id: 127,
    path: routes.purchaseList,
    name: "purchase-list",
    element: <PurchaseList />,
    perm: "purchase-view",
    route: Route,
  },
  {
    id: 128,
    path: routes.purchaseUpload,
    name: "purchase-upload",
    element: <PurchaseUpload />,
    perm: "purchase-add",
    route: Route,
  },
  {
    id: 130,
    path: routes.purchaseDetails,
    name: "purchase-details",
    element: <PurchaseDetails />,
    perm: "purchase-view",
    route: Route,
  },
  {
    id: 130,
    path: routes.priceLineDetails,
    name: "price-line-details",
    element: <PriceLineDetails />,
    perm: "price-line-view",
    route: Route,
  },
  {
    id: 127,
    path: routes.salesList,
    name: "sales-list",
    element: <SalesList />,
    perm: "sale-view",
    route: Route,
  },
  {
    id: 130,
    path: routes.salesDetails,
    name: "sales-details",
    element: <SalesDetails />,
    perm: "sale-view",
    route: Route,
  },
  {
    id: 131,
    path: routes.variantList,
    name: "variant-list",
    element: <VarientList />,
    perm: "product-view",
    route: Route,
  },
  {
    id: 132,
    path: routes.variantCreate,
    name: "variant-create",
    element: <VarientForm />,
    perm: "product-add",
    route: Route,
  },
  {
    id: 133,
    path: routes.variantEdit,
    name: "variant-edit",
    element: <VarientForm />,
    perm: "product-edit",
    route: Route,
  },
  {
    id: 134,
    path: routes.variantDetails,
    name: "variant-details",
    element: <VarientDetails />,
    perm: "product-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.gs1List,
    name: "gs1-list",
    element: <GS1List />,
    perm: "general-report-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.liveStream,
    name: "/live-stream",
    element: <LiveStream />,
    perm: "general-report-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.catalogImageDetails,
    name: "catalog/image-details",
    element: <CatalogImageDetails />,
    perm: "catalog-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.productImageDetails,
    name: "product/image-details",
    element: <ProductImageDetails />,
    perm: "product-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.catalogList,
    name: "catalog-list",
    element: <CatalogList />,
    perm: "catalog-view",
    route: Route,
  },
  {
    id: 136,
    path: routes.catalogCreate,
    name: "catalog-create",
    element: <CatalogForm />,
    perm: "catalog-add",
    route: Route,
  },
  {
    id: 137,
    path: routes.catalogEdit,
    name: "catalog-edit",
    element: <CatalogForm />,
    perm: "catalog-edit",
    route: Route,
  },
  {
    id: 138,
    path: routes.catalogDetails,
    name: "catalog-details",
    element: <CatalogDetails />,
    perm: "catalog-view",
    route: Route,
  },
  {
    id: 201,
    path: routes.objectupload,
    name: "object-upload",
    element: <ObjectUpload />,
    perm: "catalog-view",
    route: Route,
  },
  {
    id: 135,
    path: routes.productList,
    name: "product-list",
    element: <ProductList />,
    perm: "product-view",
    route: Route,
  },
  // {
  //   id: 136,
  //   path: routes.productCreate,
  //   name: "product-create",
  //   element: <ProductForm />,
  //   perm: "product-add",
  //   route: Route,
  // },
  {
    id: 137,
    path: routes.productEdit,
    name: "product-edit",
    element: <ProductForm />,
    perm: "product-edit",
    route: Route,
  },
  {
    id: 138,
    path: routes.productDetails,
    name: "product-details",
    element: <ProductDetails />,
    perm: "product-view",
    route: Route,
  },
  {
    id: 138,
    path: routes.ProductFromCatalog,
    name: "product-from-catalog",
    element: <ProductFromCatalog />,
    perm: "product-add-from-catalog",
    route: Route,
  },
  {
    id: 138,
    path: routes.PriceLineFromPurchase,
    name: "price-line-from-purchase",
    element: <PriceLineFromPurchase />,
    perm: "price-line-add",
    route: Route,
  },
  {
    id: 138,
    path: routes.MainStockFromPurchase,
    name: "main-stock-from-purchase",
    element: <MainStockFromPurchase />,
    perm: "main-stock-add",
    route: Route,
  },
  {
    id: 138,
    path: routes.DistributionFromMainStock,
    name: "main-stock-from-purchase",
    element: <DistributionFromMainStock />,
    perm: "main-stock-add",
    route: Route,
  },
  {
    id: 138,
    path: routes.AddProductGroupList,
    name: "add-product-group-list",
    element: <AddProductGroupList />,
    perm: "product-group-list-add",
    route: Route,
  },
  {
    id: 139,
    path: routes.priceLinelist,
    name: "price-line-list",
    perm: "price-line-view",
    element: <PriceLineList />,
    route: Route,
  },
  {
    id: 141,
    path: routes.myProfile,
    name: "my-profile",
    element: <MyProfile />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 142,
    path: routes.mainStockList,
    name: "main-stock-list",
    element: <MainStockList />,
    perm: "main-stock-view",
    route: Route,
  },
  {
    id: 143,
    path: routes.mainStockCreate,
    name: "main-stock-create",
    element: <MainStockForm />,
    perm: "main-stock-add",
    route: Route,
  },
  {
    id: 144,
    path: routes.mainStockEdit,
    name: "main-stock-edit",
    element: <MainStockForm />,
    perm: "main-stock-edit",
    route: Route,
  },
  {
    id: 145,
    path: routes.distributionList,
    name: "distribution-list",
    element: <DistributionList />,
    perm: "distribution-view",
    route: Route,
  },
  {
    id: 148,
    path: routes.outletStockList,
    name: "outlet-stock-list",
    element: <OutletStockList />,
    perm: "outlet-stock-view",
    route: Route,
  },
  {
    id: 149,
    path: routes.ProductTypeList,
    name: "product-type-list",
    element: <ProductTypeList />,
    perm: "product-type-view",
    route: Route,
  },
  {
    id: 150,
    path: routes.pledgePriceList,
    name: "pledge-price-list",
    element: <PledgePriceList />,
    perm: "pledge-view",
    route: Route,
  },
  {
    id: 151,
    path: routes.pledgePriceCreate,
    name: "pledge-price-create",
    element: <PledgePriceForm />,
    perm: "pledge-add",
    route: Route,
  },
  {
    id: 152,
    path: routes.pledgePriceEdit,
    name: "pledge-price-edit",
    element: <PledgePriceForm />,
    perm: "pledge-edit",
    route: Route,
  },
  {
    id: 150,
    path: routes.CountryList,
    name: "country-list",
    element: <CountryList />,
    perm: "country-view",
    route: Route,
  },
  {
    id: 151,
    path: routes.CountryCreate,
    name: "country-create",
    element: <CountryForm />,
    perm: "country-add",
    route: Route,
  },
  {
    id: 152,
    path: routes.CountryEdit,
    name: "country-edit",
    element: <CountryForm />,
    perm: "country-edit",
    route: Route,
  },
  {
    id: 138,
    path: routes.CountryDetails,
    name: "country-details",
    element: <CountryDetails />,
    perm: "country-view",
    route: Route,
  },
  {
    id: 153,
    path: routes.roleList,
    name: "role-list",
    element: <RoleList />,
    perm: "role-view",
    route: Route,
  },
  {
    id: 154,
    path: routes.roleCreate,
    name: "role-create",
    element: <RoleForm />,
    perm: "role-add",
    route: Route,
  },
  {
    id: 155,
    path: routes.roleEdit,
    name: "role-edit",
    element: <RoleForm />,
    perm: "role-edit",
    route: Route,
  },
  {
    id: 161,
    path: routes.productGroupList,
    name: "product-group-list",
    element: <ProductGroupList />,
    perm: "product-group-view",
    route: Route,
  },
  {
    id: 162,
    path: routes.productGroupCreate,
    name: "product-group-create",
    element: <ProductGroupForm />,
    perm: "product-group-add",
    route: Route,
  },
  {
    id: 163,
    path: routes.productGroupEdit,
    name: "product-group-edit",
    element: <ProductGroupForm />,
    perm: "product-group-edit",
    route: Route,
  },
  {
    id: 138,
    path: routes.productGroupDetail,
    name: "product-group-detail",
    element: <ProductGroupDetails />,
    perm: "product-group-view",
    route: Route,
  },
  {
    id: 164,
    path: routes.productGroupListIndex,
    name: "product-group-list-index",
    element: <ProductGroupListIndex />,
    perm: "product-group-list-view",
    route: Route,
  },
  {
    id: 165,
    path: routes.logList,
    name: "logs-list",
    element: <LogsList />,
    perm: "log-view",
    route: Route,
  },
  {
    id: 21,
    path: all_routes.threeDCatalogViewExpanded,
    name: "3d-sample-catalog",
    element: <ThreedFileWindowIphoneExpanded />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 1116,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    perm: "exempt",
    route: Route,
  },
  {
    id: 21,
    path: "3d-sample",
    name: "3d-sample",
    element: <ThreedFilePage />,
    perm: "exempt",
    route: Route,
  },
];

export const pagesRoute = [
  {
    id: 2,
    path: routes.signintwo,
    name: "signintwo",
    element: <SigninTwo />,
    route: Route,
  },
  {
    id: 7,
    path: routes.forgotPasswordTwo,
    name: "forgotPasswordTwo",
    element: <ForgotpasswordTwo />,
    route: Route,
  },
  {
    id: 9,
    path: routes.verifyOtp,
    name: "verifyotp",
    element: <VerifyOtp />,
    route: Route,
  },
  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },
];
