import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createFaq = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.faq.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateFaq = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.faq.update}`,
    true,
    data,
    json?false:true,
  );
};
const faqList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.faq.list}`, true, true, data);
};
const deleteFaq = async (id) => {
  const url = `${ENDPOINT.settings.faq.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getFaq = async (id) => {
  const url = `${ENDPOINT.settings.faq.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getFaq,
  createFaq,
  updateFaq,
  faqList,
  deleteFaq,
};
