import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createFaqType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.faqType.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateFaqType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.faqType.update}`,
    true,
    data,
    json?false:true,
  );
};
const faqTypeList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.faqType.list}`, true, true, data);
};
const deleteFaqType = async (id) => {
  const url = `${ENDPOINT.settings.faqType.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getFaqType = async (id) => {
  const url = `${ENDPOINT.settings.faqType.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getFaqType,
  createFaqType,
  updateFaqType,
  faqTypeList,
  deleteFaqType
};
