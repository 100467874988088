import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/user-managment/user.service";
import roleHttpService from "../../../services/http/user-managment/role.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import UnlimitProfilePicture from "../../../components/ui-kit/unlimit-profile-picture";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

const UserForm = () => {
  const initialpermSchema = [{id:1,name:"SAAdmin"}, {id:2,name:"UnlimitAdmin"}, {id:3,name:"Vendor"}, {id:4,name:"Outlet"}]
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [permSchema, setPermSchema] = useState(initialpermSchema);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendor, setVendor] = useState([]);
  
  const { isVendorOrOutlet, isOutletRole,isVendorRole } = useRoleAndPerm();
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [roles, setRoles] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const vendors = useSelector((state) => state.vendors.vendors);
  const auth = useSelector((state) => state.auth);
  const setInitialSchema = () => {
    const roleType = auth.roles[0].role_type;
    const roleIndex = initialpermSchema.findIndex((perm) => perm?.name === roleType);
    const schema = initialpermSchema.slice(roleIndex);
    setPermSchema(schema);
  };
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getUser();
    }
  };

  useEffect(() => {
    setInitialSchema()
    fetchPredefinedValues();
    if (id) {
      getUser();
    }
    setVendor(vendors?.data?.data);
  }, [id]);

  const getUser = async () => {
    try {
      setPageLoading(true);
      await httpService
        .getUser(id)
        .then(async (response) => {
          if (response && response.status === 200) {
            const data = response.data.data;
            hydratePredefinedValues(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("is_active", data.is_active == 1 ? true : false);
    const selectedRoleType =permSchema.find((perm) => perm.name === data.roles[0]?.role_type)
    setValue(
      "roleType",
      {
        label:selectedRoleType.name,
        value:selectedRoleType.id
      }
    );
    if(data.vendor?.id) {
      setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo_url,
    });
    setSelectedVendor(vendors?.data?.data?.find((v)=> v.id === data.vendor.id))
  }
    if(data.outlet?.name) setValue("outlet_id", {
      ...data.outlet,
      label: data.outlet.name,
      value: data.outlet.id,
    });
    if (data.roles[0]) {
      setValue("role", {
        ...data.roles[0],
        label: data.roles[0]?.name,
        value: data.roles[0]?.id,
      });
    }
    setExistingImageUrl(data.image);
  };
  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      roleType === permSchema[2].name && formData.append("vendor_id", selectedVendor.id)
      roleType === permSchema[3].name && formData.append("outlet_id", data.outlet_id.id)
      !id && formData.append("password", data.password);
      !id &&
        formData.append("password_confirmation", data.password_confirmation);
      
      formData.append("role", data.role.id);
      formData.append("is_active", data.is_active ? 1 : 0);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (id) {
        formData.append("id", id);
        await httpService
          .updateUser(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(t("user.userUpdateSuccess"));
              setTimeout(() => {
                navigate(all_routes.userList);
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createUser(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new user
  const createUser = async (formData) => {
    try {
      await httpService
        .createUser(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(t("user.userCreateSuccess"));
            navigate(all_routes.userList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const handleRemoveImage = () => {
    setImageUrl("");
    const inputElement = document.getElementById("image");
    if (inputElement) {
      inputElement.value = "";
    }
    if (id) setExistingImageUrl("");
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("user.manageUsers"), link: "/user-list" },
    { label: id ? t("user.editUser") : t("user.addUser"), active: true },
  ];

  async function fetchUserType(roletype) {
    setRoleType(roletype)
    setRoles([])
    setValue('role',null)
    setRoleLoading(true);
    try {
      const roles = await roleHttpService.roleList({ role_type: roletype });
      const activeRoles = roles?.data?.data?.data?.filter(
        (item) => item.is_active === "1"
      );
      setRoles(activeRoles);
    } catch (error) {
      console.log(error);
    } finally {
      setRoleLoading(false);
    }
  }
  
 useEffect(()=>{
    if(isVendorRole){
      const selectVendor = vendor?.find((vend)=>vend.id === auth?.user?.vendor?.id)
      const selectedVendorStorage = {
        ...selectVendor,
        value:selectVendor?.id,
        label:selectVendor?.business_name
      }
      setValue("vendor_id",selectedVendorStorage)
      handleVendorChange(selectedVendorStorage)
    }else if(isOutletRole){
      setValue("vendor_id",{
        ...auth?.user?.outlet?.vendor,
        value:auth?.user?.outlet?.vendor?.id,
        label:auth?.user?.outlet?.vendor?.business_name
      })
      setValue("outlet_id", {
        ...auth?.user?.outlet,
        label: auth?.user?.outlet.name,
        value: auth?.user?.outlet.id,
        logo: auth?.user?.outlet.image_url,
      });
    }
  },[vendor])
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setValue("outlet_id", null)
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("user.userManagment")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* User Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="row">
                      <div className="col-md-6 mb-3">
                          <UnlimitSelect
                            label={t("role.permSchema")}
                            id="roleType"
                            errors={errors}
                            control={control}
                            register={register}
                            options={permSchema}
                            onChange={(selectedOption) => fetchUserType(selectedOption?.name)}
                            isDisabled={id === user.id}
                            rules={{
                              required: t("inputField.validation.required"),
                            }}
                            placeholder={t("role.selectSchema")}
                            optionLabel="name"
                            optionValue="id"
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitSelect
                            label={t("role.role")}
                            id="role"
                            errors={errors}
                            control={control}
                            isLoading={roleLoading}
                            register={register}
                            options={roles}
                            isDisabled={id === user.id}
                            rules={{
                              required: t("inputField.validation.required"),
                            }}
                            placeholder={t("inputField.placeholder.ChooseRole")}
                            optionLabel="name"
                            optionValue="id"
                          />
                        </div>
                        {!(isVendorOrOutlet || (roleType === initialpermSchema[0].name || roleType === initialpermSchema[1].name)) && (
                            <div className="col-md-6">
                              <UnlimitSelect
                                label={t("vendor.vendor")}
                                id="vendor_id"
                                errors={errors}
                                control={control}
                                register={register}
                                onChange={handleVendorChange}
                                rules={{
                                  required: t("inputField.validation.required"),
                                }}
                                placeholder={t(
                                  "inputField.placeholder.chooseVendor"
                                )}
                                options={vendor}
                                showLogo={true}
                                optionLabel="business_name"
                                optionValue="id"
                              />
                            </div>
                          )}
                        {!(isOutletRole || (roleType === initialpermSchema[0]?.name || roleType === initialpermSchema[1]?.name || initialpermSchema[2] ? roleType === initialpermSchema[2]?.name:false)) && (
                            <div className="col-md-6">
                              <UnlimitSelect
                                label={t("outlet.outlet")}
                                id="outlet_id"
                                errors={errors}
                                control={control}
                                register={register}
                                rules={
                                  {
                                    // required: t("inputField.validation.required"),
                                  }
                                }
                                placeholder={t(
                                  "inputField.placeholder.chooseOutlet"
                                )}
                                options={selectedVendor?.outlet}
                                showLogo={true}
                                isDisabled={
                                  !(
                                    selectedVendor?.outlet &&
                                    selectedVendor?.outlet?.length > 0
                                  )
                                }
                                optionLabel="name"
                                optionValue="id"
                              />
                            </div>
                          )}
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.name")}
                            id="name"
                            type="text"
                            placeholder={t(
                              "inputField.placeholder.enterName"
                            )}
                            register={register}
                            rules={{
                              required: t("inputField.validation.required"),
                              maxLength: {
                                value: 50,
                                message: t("inputField.validation.maxLength", {
                                  value: 50,
                                }),
                              },
                            }}
                            autoComplete="off"
                            error={errors.name}
                            errorMessage={errors.name?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.email")}
                            id="email"
                            type="email"
                            placeholder={t("inputField.placeholder.enterEmail")}
                            register={register}
                            disabled={id === user.id}
                            rules={{
                              required: t("inputField.validation.required"),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t(
                                  "inputField.validation.emailPattern"
                                ),
                              },
                            }}
                            error={errors.email}
                            errorMessage={errors.email?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.mobile")}
                            id="phone"
                            type="text"
                            placeholder={t(
                              "inputField.placeholder.enterMobileNo"
                            )}
                            register={register}
                            rules={{
                              required: t("inputField.validation.required"),
                              pattern: {
                                value: /^\+?\d+$/,
                                message: t(
                                  "inputField.validation.phonePattern"
                                ),
                              },
                              maxLength: {
                                value: 20,
                                message: t("inputField.validation.maxLength", {
                                  value: 20,
                                }),
                              },
                            }}
                            error={errors.phone}
                            errorMessage={errors.phone?.message}
                          />
                        </div>
                        <div className="col-md-12">
                        <div className="row">
                        {!id && (
                          <>
                            <div className="col-md-6">
                              <UnlimitInput
                                label={t("inputField.label.password")}
                                id="password"
                                type="password"
                                placeholder={t(
                                  "inputField.placeholder.enterPassword"
                                )}
                                register={register}
                                rules={{
                                  required: t("inputField.validation.required"),
                                  minLength: {
                                    value: 8,
                                    message: t(
                                      "inputField.validation.minLength",
                                      { value: 8 }
                                    ),
                                  },
                                }}
                                error={errors.password}
                                errorMessage={errors.password?.message}
                              />
                            </div>
                            <div className="col-md-6">
                              <UnlimitInput
                                label={t("inputField.label.confirmPassword")}
                                id="password_confirmation"
                                type="password"
                                placeholder={t(
                                  "inputField.placeholder.enterConfirmPassword"
                                )}
                                register={register}
                                rules={{
                                  required: t("inputField.validation.required"),
                                  minLength: {
                                    value: 8,
                                    message: t(
                                      "inputField.validation.minLength",
                                      { value: 8 }
                                    ),
                                  },
                                  validate: (value) =>
                                    value === getValues("password") ||
                                    t("inputField.validation.matchPassword"),
                                }}
                                error={errors.password_confirmation}
                                errorMessage={
                                  errors.password_confirmation?.message
                                }
                              />
                            </div>
                          </>
                        )}
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div style={{height:'200px'}}>

                      <UnlimitProfilePicture
                        label={t("inputField.label.profilePicture")}
                        id="image"
                        register={register}
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        error={errors.image}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                        handleRemoveImage={handleRemoveImage}
                        eid={id}
                        imageUrl={imageUrl}
                        accept=".jpeg, .png, .jpg"
                        existingImageUrl={existingImageUrl}
                        />
                        </div>
                    </div>
                  </div>
                  <div className="input-blocks m-0 mt-3">
                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                      <UnlimitToggle
                        isChecked={id ? false : true}
                        label={t("tableColumnName.status")}
                        id="is_active"
                        register={register}
                        disabled={id === user.id}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.userList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* User Form End */}
      </div>
    </div>
  );
};

export default UserForm;
