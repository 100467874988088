import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import httpService from "../../../services/http/peoples/customers.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
import { useSelectedCountry } from "../../../feature-module/dashboard/hooks/useSelectedCountry";
import formatNumber from "../../../utils/numberFunction";
const CustomersList = () => {
  const { hasPermission,isVendorOrOutlet } = useRoleAndPerm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState("");
  const vendors = useSelector((state) => state.vendors.vendors);
  const selectedCountry = useSelectedCountry();
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);

  const getCustomerList = async (
    pagination = paginationParams.pagination,
    filters
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor?.value,
      ...filters,
    };
    setLoading(true);
    await httpService
      .customerList(data)
      .then((response) => {
        if (response.status == 200) {
          setCustomers(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, sorter) => {
    // cpr, name, email, phone r id
    const filters = {
      name: nameFilter,
      id: idFilter,
      email: emailFilter,
      phone: phoneFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value) // Double negation for truthy check
    );
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    if(!pagination){
      pagination = initialPagination
    }
    await getCustomerList(pagination, nonNullFilters);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    if(isVendorOrOutlet){
      getCustomerList()
    }else{
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];
      
      if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }
  }, []);
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteCustomer(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("customer.customerDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getCustomerList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("customer.customerCode"),
      dataIndex: "customer_code",
    },
    {
      title: t("customer.loyaltyID"),
      dataIndex: "loyalty_id",
    },
    {
      title: t("tableColumnName.name"),
      dataIndex: "name",
      render: (text, record) => record.first_name + " " + record.middle_name + " " + record.last_name,
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.availablePoints"),
      dataIndex: "loyalty_points",
      align: "center",
      render:(text)=> formatNumber(text,selectedCountry)
    },
    {
      title: t("tableColumnName.country"),
      key: "country",
      dataIndex: "country_id",
      sorter: true,
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record.country?.iso2 && (
            <UnlimitCountryIcon iso2={record.country?.iso2} />
          )}
          <div className="ms-2">{record.country?.name}</div>
        </div>
      ),
    },
    {
      title: t("customer.userType"),
      // dataIndex: "cpr", Danish or international
      render: (text, record) =>
        record.cpr ? (
          <span className="badge badge-linesuccess">
            <a>Danish</a>
          </span>
        ) : (
          <span className="badge badge-linewarning">
            <a>International</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) =>
        record.is_active == 1 ? (
          <span className="badge badge-linesuccess">
            <a>{t("general.actions.active")}</a>
          </span>
        ) : (
          <span className="badge badge-linedanger">
            <a>{t("general.actions.deactive")}</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["customer-view", "customer-delete"]),
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["customer-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/customers/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["customer-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </div>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("customer.customerList"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("customer.customerList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
           {!isVendorOrOutlet &&  <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder={t("customer.loyaltyID")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      onChange={(e) => setIdFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterEmail")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-2 col-sm-4 col-12">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder={t("inputField.placeholder.enterPhone")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      value={phoneFilter}
                      onChange={(e) => setPhoneFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-1 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={customers}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
