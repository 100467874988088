import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import httpService from "../../../services/http/settings/faq.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment/moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useSelector } from "react-redux";
import FaqDetail from "../../../components/faq-detail";
const FaqList = () => {
  const vendors = useSelector((state) => state.vendors.vendors);
  const [vendor, setVendor] = useState([]);
  const { hasPermission } = useRoleAndPerm();
  const [faq, setFaq] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [faqs, setFaqs] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);

  const getFaqsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .faqList(data)
      .then((response) => {
        if (response.status == 200) {
          setFaqs(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getFaqsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    getFaqsList();
  }, []);

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteFaq(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("faq.faq"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getFaqsList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onHideModal = async () => {
    console.log("hide modal");
    setFaq(null);
    await getFaqsList();
  };
  const editFaq = (row) => {
    setFaq(row);
    const addButton = document.querySelector(".btn-added");
    if (addButton) {
      addButton.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.faqType"),
      key: "question",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <div className="d-flex">
            <ImageWithBasePath
            type="link"
            src={record?.faq_type?.icon}
            alt="Outlet"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
            <div>{record?.faq_type?.name}</div>
          </div>
        );
      },
    },
    {
      title: t("tableColumnName.question"),
      dataIndex: "question",
    },
    {
      title: t("tableColumnName.answer"),
      dataIndex: "answer",
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
      sorter: true,
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => {
        return (
          <div className="edit-delete-action">
            {hasPermission(["language-edit"]) && (
              <a
                className="me-2 p-2"
                onClick={() => editFaq(record)}
              >
                <i className="feather-edit-2"></i>
              </a>
            )}

            {hasPermission(["language-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        );
      },
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("inputField.label.faq"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("inputField.label.faq")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div
              className="page-btn"
              style={{
                display: hasPermission(["language-add"]) ? "block" : "none",
              }}
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-faq"
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top row">
                <div className="col-lg-5 col-sm-5 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterLanguage")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={faqs}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <FaqDetail
        onHide={onHideModal}
        faq={faq}
      />
    </div>
  );
};

export default FaqList;
